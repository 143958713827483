<template>
  <div >
    <button v-if="referrer" class="ButtonBack"
            onclick="history.back();">
      <img src="@/assets/icons/backArrow.svg" alt="">
      Back
    </button>
    <a v-if="!referrer" class="ButtonBack"
       :href="landingUrl">
      <img src="@/assets/icons/backArrow.svg" alt="">
      Back
    </a>
    <div class="d-flex align-items-center gap-2">
      <span style="font-size: 44px; font-weight: 600; font-family: 'Inter', sans-serif;">AR</span>
      <h4 class="title">Automated <br/> Rules</h4>
    </div>

    <div class="pt-5">
      <form @submit.prevent="login">
        <div class="col-12">
          <LandingInput
            id="userLogin"
            type="email"
            :required="true"
            @changeValue="changeValue"
            placeholder="E-mail"
            :value-key="'email'"
            class="w-100"
            :v="$v.loginData.email"
            :inputClass="emailInputClass"
          />
          <span class="error p-2" v-if="!$v.loginData.email.required && $v.loginData.email.$dirty">{{
              $t('error.required', { field: 'Email' })
            }}</span>
          <span class="error p-2" v-if="!$v.loginData.email.email">{{ $t('error.email', { field: 'Email' }) }}</span>
          <span class="error p-2" v-if="emailError.length">{{ emailError }}</span>
        </div>
        <div class="col-12 mt-4">
          <LandingInput
            id="password"
            type="password"
            :required="true"
            @changeValue="changeValue"
            placeholder="Password"
            :value-key="'password'"
            class="w-100"
            :v="$v.loginData.password"
            :input-class="passwordInputClass"
          />
          <span class="error p-2"
                v-if="!$v.loginData.password.required && $v.loginData.password.$dirty"
          >
            {{ $t('error.required', { field: 'Password' }) }}
          </span>
          <span class="error p-2"
                v-if="!$v.loginData.password.minLength"
          >
            {{
              $t('error.minLength', {
                field: 'Password',
                length: 6
              })
            }}
          </span>
          <span class="error p-2" v-if="passwordError.length">{{ passwordError }}</span>
        </div>
        <div class="col-12 mt-5 mb-4 d-flex">
          <LandingCheckbox
            :container_class="'form-check'"
            :name="'rememberMe'"
            :id="'rememberMe'"
            :label="'Remember me'"
            @change="() => {return undefined}"
            :value="true"
            :checked="undefined"
            :error="undefined"
            :fontSize="'16px'"
          >
          </LandingCheckbox>
        </div>
          <button class="standard-btn signin-btn" type="submit">LOG IN</button>
        <div class="link_group">
          <router-link :to="{ name: 'Forgot Password Form' }" class="link">Forgot
            password?
          </router-link>
          <router-link :to="{ name: 'SignUp' }" class='link'>No account yet?</router-link>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex'
import LandingInput from '@/components/landings/LandingInput.vue'
import LandingCheckbox from '@/components/landings/LandingCheckbox.vue'
import { required, minLength, email } from 'vuelidate/lib/validators'
import { messages } from '../../i18n/messages'
import { FACEBOOK_SERVICE } from '@/constants/FbAutomatedRule'

export default Vue.extend({
  components: {
    LandingInput,
    LandingCheckbox
  },
  metaInfo: {
    title: 'Automate Your Digital Advertising | AutomatedRules',
    meta: [
      {
        name: 'description',
        content: 'Automate and Optimize your digital advertising management.'
      }
    ]
  },
  props: {
    redirect: {
      required: false,
      default: null
    }
  },
  data: () => ({
    loginData: {
      email: null,
      password: null
    },
    referrer: false
  }),
  validations: {
    loginData: {
      email: {
        required,
        email
      },
      password: {
        required,
        minLength: minLength(6)
      }
    }
  },
  i18n: {
    messages: messages
  },
  mounted () {
    const url = new URL(document.location.href)
    this.referrer = url.searchParams.get('back')
  },
  computed: {
    ...mapGetters('auth', ['is_authorized']),
    ...mapGetters('profile', ['currentUser']),
    ...mapGetters('exceptions', ['exceptionMessageBySourceParameter']),
    emailError () {
      return this.exceptionMessageBySourceParameter('email') || ''
    },
    passwordError () {
      return this.exceptionMessageBySourceParameter('password') || ''
    },
    landingUrl () {
      return process.env.VUE_APP_LANDING_URL
    },
    emailInputClass () {
      return (this.emailError.length > 0 || this.$v.loginData.email.$error) ? 'error' : null
    },
    passwordInputClass () {
      return (this.passwordError.length > 0 || this.$v.loginData.password.$error) ? 'error' : null
    }
  },
  methods: {
    ...mapActions('auth', ['auth']),
    ...mapActions('profile', ['getProfile']),
    ...mapActions('adAccounts', ['loadAccounts']),
    ...mapActions('exceptions', ['addExceptions', 'clearExceptions']),
    ...mapActions('notifications', { addExceptionToNotifications: 'addExceptions' }),
    changeValue (data) {
      this.loginData[data.key] = data.value
      this.clearExceptions()
    },

    login () {
      if (!this.$v.$invalid) {
        this.auth({
          email: this.loginData.email,
          password: this.loginData.password
        }).then(() => {
          this.getProfile()
          this.loadAccounts({
            search: {
              service: 'facebook',
              target: 'ads_management'
            }
          })
          if (this.$gtm && this.$gtm.enabled()) {
            // eslint-disable-next-line no-unused-expressions
            window.dataLayer?.push({
              event: 'login',
              method: 'email',
              userId: this.currentUser.id
            })
          }
          if (this.redirect) {
            this.$router.push(this.redirect)
          } else {
            this.$router.push({
              name: 'AutomatedRules',
              params: { service: FACEBOOK_SERVICE }
            })
          }
        }).catch((e) => {
          if (e.response && e.response.data.errors) {
            this.addExceptionToNotifications(e.response.data)
          }
        })
      } else {
        this.$v.$touch()
      }
    }
  }
})
</script>

<style lang="scss" scoped>

.form-check {
  padding-left: 0;
}

.title {
  font-weight: 600;
  font-size: 16px;
  font-family: 'Inter', sans-serif;
  line-height: 17.19px;
  color: #2E2E2E;
}

button.signin-btn {
  background: #00BAFF;
  border-radius: 30px;
  font-family: 'Roboto Flex', sans-serif;
  min-height: 50px;
  width: 100%;
  font-weight: 700;
  font-size: 15px;
  line-height: 1.60;
  text-transform: uppercase;
  color: #ffffff;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.25);
}

.link {
  font-family: "Merge One", sans-serif;
  font-size: 0.875rem;
  line-height: 1.43;
  text-decoration: underline;
  color: #00BAFF;
  margin: 0;
}
.link_group {
  display: flex;
  margin-top: 62px;
  gap: 32px;
  @media (max-width: 576px) {
    justify-content: space-between;
  }
}
</style>
